import {
  Badge,
  Box,
  Group,
  MantineTheme,
  ScrollArea,
  Space,
  Stack,
  Switch,
  Text,
} from '@mantine/core';
import React, { useContext, useState } from 'react';
import IHistoryEntry from '../../models/IHistoryEntry';
import { UserElement } from '../entityelements/UserElement';
import { LocationElement } from '../entityelements/LocationElement';
import { ResourceElement } from '../entityelements/ResourceElement';
import { InquiryPriorityElement } from '../inquiries/InquiryPriorityElement';
import { INQUIRY_STATUS_ENUM_VALUES } from '../../api_enums/INQUIRY_STATUS_ENUM';
import { AuthContext, AuthContextType } from '../../context/AuthProvider';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const supportedHistoryKeys = [
  'priority',
  'deliver_to',
  'status',
  'amount',
  'inquired_is',
];

const boxSx = (theme: MantineTheme) => ({
  backgroundColor:
    theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
  padding: theme.spacing.xs,
  borderRadius: theme.radius.md,
  cursor: 'pointer',
});

function ChangeHistoryElement(props: {
  change: {
    [key: string]: { current: string | number; before: string | number };
  };
}) {
  return (
    <>
      {Object.keys(props.change).map((k, idx) => {
        if ('priority' === k) {
          // TODO only use this if also is inquiry
          return (
            <Text key={idx}>
              {' '}
              Priorität wurde von{' '}
              <InquiryPriorityElement
                value={props.change[k].before.toString()}
              />
              auf{' '}
              <InquiryPriorityElement
                value={props.change[k].current.toString()}
              />{' '}
              herabgesetzt
            </Text>
          );
        } else if ('deliver_to' === k) {
          return (
            <Text key={idx}>
              {' '}
              Lieferort wurde von{' '}
              <Badge color="red" radius="xs" size="lg">
                <LocationElement id={props.change[k].before as number} />
              </Badge>{' '}
              auf{' '}
              <Badge color="lime" radius="xs" size="lg">
                <LocationElement id={props.change[k].current as number} />
              </Badge>{' '}
              geändert
            </Text>
          );
        } else if ('status' === k) {
          return (
            <Text key={idx}>
              {' '}
              Status wurde von{' '}
              <Badge color="red" radius="xs" size="lg">
                {
                  INQUIRY_STATUS_ENUM_VALUES.get(
                    props.change[k].before.toString(),
                  )?.label
                }
              </Badge>{' '}
              auf{' '}
              <Badge color="lime" radius="xs" size="lg">
                {
                  INQUIRY_STATUS_ENUM_VALUES.get(
                    props.change[k].current.toString(),
                  )?.label
                }
              </Badge>{' '}
              geändert
            </Text>
          );
        } else if ('amount' === k) {
          return (
            <Text key={idx}>
              {' '}
              Anzahl wurde von{' '}
              <Badge color="red" radius="xs" size="lg">
                {props.change[k].before.toString()}
              </Badge>{' '}
              auf{' '}
              <Badge color="lime" radius="xs" size="lg">
                {props.change[k].current.toString()}
              </Badge>{' '}
              geändert
            </Text>
          );
        } else if ('inquired_is' === k) {
          return (
            <Text key={idx}>
              {' '}
              Ressource wurde von{' '}
              <Badge color="red" radius="xs" size="lg">
                <ResourceElement id={props.change[k].before as number} />
              </Badge>{' '}
              auf{' '}
              <Badge color="lime" radius="xs" size="lg">
                <ResourceElement id={props.change[k].current as number} />
              </Badge>{' '}
              geändert
            </Text>
          );
        } else {
          console.log('unsupported history key: ' + k);
          return <></>;
        }
      })}
    </>
  );
}

export function ChangeHistory(props: { history: IHistoryEntry[] }) {
  const { t } = useTranslation();
  const [showOwnChanges, setShowOwnChanges] = useState(false);

  const { userInfo } = useContext(AuthContext) as AuthContextType;

  return (
    <Stack>
      <Switch
        color="green"
        label={t('ChangeHistory.ViewChanges')}
        checked={showOwnChanges}
        onChange={(event) => setShowOwnChanges(event.currentTarget.checked)}
      />

      <ScrollArea style={{ height: 300 }}>
        {props.history.map((ch) => {
          return (
            <>
              {(showOwnChanges || ch.by !== userInfo?.user_id) &&
              ch.change &&
              Object.keys(ch.change).some((element) =>
                supportedHistoryKeys.includes(element),
              ) ? (
                <>
                  <Box sx={boxSx}>
                    <Group>
                      <Text weight={700}>
                        {' '}
                        {moment
                          .utc(ch.at)
                          .local()
                          .format('DD.MM.YYYY - HH:mm:ss')}{' '}
                      </Text>
                      <UserElement id={ch.by} />
                      <ChangeHistoryElement change={ch.change} />
                    </Group>
                  </Box>
                  <Space h="xs" />
                </>
              ) : (
                <></>
              )}
            </>
          );
        })}
        {props.history.length == 0 && (
          <Text>{t('ChangeHistory.NoChange')}</Text>
        )}
        {props.history.filter((v) => v.by !== userInfo?.user_id).length === 0 &&
          !showOwnChanges && <Text>{t('ChangeHistory.NoOneChanged')}</Text>}
      </ScrollArea>
    </Stack>
  );
}
