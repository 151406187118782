import {
  Checkbox,
  Group,
  Paper,
  Skeleton,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';
import { ResourceEditDialog } from './ResourceEditDialog';
import { ResourceDeleteDialog } from './ResourceDeleteDialog';
import { ResourceCreateDialog } from './ResourceCreateDialog';
import React, { useContext } from 'react';
import IResource from '../../models/IResource';
import { ResourceContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import { RESOURCE_TYPE_ENUM_VALUES } from '../../api_enums/RESOURCE_TYPE_ENUM';
import { useTranslation } from 'react-i18next';
import IPackageUnit from '../../models/IPackageUnit';
import { VOLUME_UNIT_ENUM_VALUES } from '../../api_enums/VOLUME_UNIT_ENUM';

// TODO: Tabelle durch context grid ersetzten (react-table) und Daten durch websockets updaten

function createRows(elements: IResource[]) {
  return elements.map((element) => {
    return (
      <tr key={element?.id}>
        <td> {element?.name} </td>
        <td> {RESOURCE_TYPE_ENUM_VALUES.get(element?.type)?.label} </td>
        <td> {(element?.package_unit as IPackageUnit)?.name} </td>
        <td>
          {' '}
          {element?.weight} {element?.weight_unit}{' '}
        </td>
        <td>
          {' '}
          {element?.volume}{' '}
          {VOLUME_UNIT_ENUM_VALUES.get(element?.volume_unit).label}
        </td>
        <td>
          <Checkbox checked={element?.is_active} disabled />
        </td>
        <td>
          <Group spacing="xs">
            <ResourceEditDialog resource={element} />
            <ResourceDeleteDialog resource={element} />
          </Group>
        </td>
      </tr>
    );
  });
}

function ResourceTable() {
  const { t } = useTranslation();
  const ctx = useContext(ResourceContext) as AbstractContextType<IResource>;

  // TODO show skeleton when data is being loaded
  return (
    <Paper withBorder radius="md" p="xs">
      <Stack>
        <Title order={2}>Ressourcen</Title>
        <Group>
          <ResourceCreateDialog />
        </Group>
        <Table striped verticalSpacing="xs" fontSize="md">
          <thead>
            <tr>
              <th>{t('ResourceTable.Name')}</th>
              <th>{t('ResourceTable.Type')}</th>
              <th>{t('ResourceTable.PackingUnit')}</th>
              <th>{t('ResourceTable.Weight')}</th>
              <th>{t('ResourceTable.Volume')}</th>
              <th>{t('ResourceTable.Active')}</th>
              <th>{t('ResourceTable.Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {createRows(ctx.entities)}

            {ctx.loading &&
              /* TODO ajust skeleton */
              [1, 2].map((id) => {
                return (
                  <tr key={id}>
                    <td>
                      <Skeleton height={12} mt={6} width="50%" radius="xl" />
                    </td>
                    <td>
                      <Skeleton height={12} mt={6} width="70%" radius="xl" />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {!ctx.loading && ctx.entities.length == 0 && (
          <Text align="center">{t('ResourceTable.NoResourceAvailable')}</Text>
        )}
      </Stack>
    </Paper>
  );
}

export default ResourceTable;
