import React, { useContext, useState } from 'react';
import { ActionIcon, Button, Group, Modal, Stack, Text } from '@mantine/core';
import { Trash } from 'tabler-icons-react';
import { showAppNotifcation } from '../../utility/NotificationConfigs';
import { ResourceContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import IResource from '../../models/IResource';
import ResourceService from '../../services/ResourceService';
import { useTranslation } from 'react-i18next';

export function ResourceDeleteDialog(props: { resource: IResource }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const ctx = useContext(ResourceContext) as AbstractContextType<IResource>;

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('ResourceDeleteDialog.Title')}
      >
        <>
          <Stack>
            <Text>
              {t('ResourceDeleteDialog.DeleteConfirm', {
                name: props.resource.name,
              })}
            </Text>
            <Group>
              <Button
                color="red"
                onClick={() => {
                  ResourceService.remove(props.resource.id)
                    .then(() => {
                      ctx.remove(props.resource.id);
                      showAppNotifcation(
                        true,
                        t('ResourceDeleteDialog.ResourceDeletedSuccessfully'),
                      );
                    })
                    .catch(() => {
                      showAppNotifcation(
                        false,
                        t('ResourceDeleteDialog.ResourceCannotDelete'),
                      );
                    })
                    .finally(() => setOpened(false));
                }}
              >
                {t('main.Delete')}
              </Button>
              <Button color="gray" onClick={() => setOpened(false)}>
                {t('main.Cancel')}
              </Button>
            </Group>
          </Stack>
        </>
      </Modal>

      <ActionIcon
        color="red"
        variant="light"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('main.Delete')}
      >
        <Trash />
      </ActionIcon>
    </>
  );
}
