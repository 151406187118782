import {
  ActionIcon,
  Divider,
  Grid,
  Modal,
  SimpleGrid,
  Space,
  Title,
} from '@mantine/core';
import React, { useState } from 'react';
import { Icon, ListDetails } from 'tabler-icons-react';
import { StatusHistory } from './StatusHistory';
import IHistoryEntry from '../../models/IHistoryEntry';
import { ChangeHistory } from './ChangeHistory';
import { useTranslation } from 'react-i18next';
import { getPrefixedInquiryId } from '../../models/IInquiry';

export function ChangeHistoryDialog(props: {
  id: number;
  history: IHistoryEntry[];
  currentStatus: string;
  statusEnum: any;
  statusEnumValues: Map<string, { label: string; icon: Icon }>;
}) {
  const { t } = useTranslation();
  const [openedDetails, setOpenedDetails] = useState(false);

  return (
    <>
      <ActionIcon
        color="indigo"
        variant="light"
        size="lg"
        onClick={() => setOpenedDetails(true)}
        title={t('ChangeHistoryDialog.Title')}
      >
        <ListDetails />
      </ActionIcon>
      <Modal
        opened={openedDetails}
        onClose={() => setOpenedDetails(false)}
        title={
          t('ChangeHistoryDialog.Title') + ' ' + getPrefixedInquiryId(props.id)
        }
        size="xl"
      >
        <SimpleGrid cols={2} spacing="lg">
          <Title order={4}>{t('ChangeHistoryDialog.StatusHistory')}</Title>
          <Title order={4}>{t('ChangeHistoryDialog.ChangeTracking')}</Title>
          <Space h="xs" />
          <Space h="xs" />
        </SimpleGrid>

        <Grid>
          <Grid.Col span={4}>
            <StatusHistory
              history={props.history}
              currentStatus={props.currentStatus}
              statusEnum={props.statusEnum}
              statusEnumValues={props.statusEnumValues}
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <Divider orientation="vertical" />
          </Grid.Col>
          <Grid.Col span={7}>
            <ChangeHistory history={props.history} />
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
}
