import React, { useContext, useState } from 'react';
import { ActionIcon, Button, Group, Modal, Stack, Text } from '@mantine/core';
import { CircleX } from 'tabler-icons-react';
import { showAppNotifcation } from '../../../utility/NotificationConfigs';
import IInquiry, { getPrefixedInquiryId } from '../../../models/IInquiry';
import InquiryService from '../../../services/InquiryService';
import { useTranslation } from 'react-i18next';
import { AuthContext, AuthContextType } from '../../../context/AuthProvider';
import ISection from '../../../models/ISection';
import { INQUIRY_STATUS_ENUM } from '../../../api_enums/INQUIRY_STATUS_ENUM';
import IUser from '../../../models/IUser';

export function ApprovalRejectDialog(props: { inquiry: IInquiry }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const { userInfo } = useContext(AuthContext) as AuthContextType;

  const checkPermission = () => {
    return (
      ((props.inquiry?.is_waiting_at as ISection)?.id ===
        userInfo?.section_id ||
        (props.inquiry?.inquired_by as IUser)?.id === userInfo?.user_id ||
        !userInfo.section_supervisor_id) &&
      ((props.inquiry?.status as INQUIRY_STATUS_ENUM) ===
        INQUIRY_STATUS_ENUM.INQUIRED ||
        (props.inquiry?.status as INQUIRY_STATUS_ENUM) ===
          INQUIRY_STATUS_ENUM.IN_APPROVAL)
    );
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('ApprovalRejectDialog.Title')}
      >
        <>
          <Stack>
            <Text>
              {t('ApprovalRejectDialog.RejectConfirm', {
                name: getPrefixedInquiryId(props.inquiry.id),
              })}
            </Text>
            <Group>
              <Button
                color="yellow"
                onClick={() => {
                  InquiryService.reject(props.inquiry.id)
                    .then(() => {
                      showAppNotifcation(
                        true,
                        t('ApprovalRejectDialog.RejectRequirement'),
                      );
                    })
                    .catch(() => {
                      showAppNotifcation(
                        false,
                        t('ApprovalRejectDialog.CannotReject'),
                      );
                    })
                    .finally(() => setOpened(false));
                }}
              >
                {t('ApprovalRejectDialog.Decline')}
              </Button>
              <Button color="gray" onClick={() => setOpened(false)}>
                {t('main.Cancel')}
              </Button>
            </Group>
          </Stack>
        </>
      </Modal>

      <ActionIcon
        disabled={!checkPermission()}
        color="red"
        variant="light"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('ApprovalRejectDialog.Decline')}
      >
        <CircleX />
      </ActionIcon>
    </>
  );
}
