import React, { useContext, useState } from 'react';
import { ActionIcon, Button, Group, Modal, Stack, Text } from '@mantine/core';
import { Trash } from 'tabler-icons-react';
import { showAppNotifcation } from '../../utility/NotificationConfigs';
import { LocationContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import LocationService from '../../services/LocationService';
import ILocation from '../../models/ILocation';
import { useTranslation } from 'react-i18next';
import { AuthContext, AuthContextType } from '../../context/AuthProvider';

export function LocationDeleteDialog(props: { location: ILocation }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const ctx = useContext(LocationContext) as AbstractContextType<ILocation>;
  const { userInfo } = useContext(AuthContext) as AuthContextType;

  /**
   * section_supervisor_id is null for the Einsatzabschnitte and not null for the Unterabschnitte.
   */
  const checkPermission = () => {
    return !userInfo.section_supervisor_id;
  };
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('LocationDeleteDialog.Title')}
      >
        <>
          <Stack>
            <Text>
              {t('main.Question')} {props.location.name}{' '}
              {t('main.DeleteConfirm')}
            </Text>
            <Group>
              <Button
                color="red"
                onClick={() => {
                  LocationService.remove(props.location.id)
                    .then(() => {
                      ctx.remove(props.location.id);
                      showAppNotifcation(
                        true,
                        t('LocationDeleteDialog.DeleteDeliveryLocation'),
                      );
                    })
                    .catch(() => {
                      showAppNotifcation(
                        false,
                        t('LocationDeleteDialog.DeliveryLocationCanNotDeleted'),
                      );
                    })
                    .finally(() => setOpened(false));
                }}
              >
                {t('main.Delete')}
              </Button>
              <Button color="gray" onClick={() => setOpened(false)}>
                {t('main.Cancel')}
              </Button>
            </Group>
          </Stack>
        </>
      </Modal>

      <ActionIcon
        disabled={!checkPermission()}
        color="red"
        variant="light"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('main.Delete')}
      >
        <Trash />
      </ActionIcon>
    </>
  );
}
