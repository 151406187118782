import React, {ReactNode, useContext, useState} from 'react';
import {BrowserRouter, Navigate, Outlet, Route, Routes} from "react-router-dom";
import './App.css';
import {AppShell, ColorScheme, ColorSchemeProvider, MantineProvider} from '@mantine/core';
import {useColorScheme} from '@mantine/hooks';
import {NotificationsProvider} from '@mantine/notifications';
import Locationstable from "./components/locations/LocationTable";
import {UserSettings} from "./components/settings/UserSettings";
import {AppHeader} from "./components/structure/AppHeader";
import {Login} from "./components/Login";
import {AuthContext, AuthContextType, AuthProvider} from "./context/AuthProvider";
import {AppNavbar} from "./components/AppNavbar";
import VehicleTable from "./components/vehicles/VehicleTable";
import {SectionManagement} from "./components/sections/SectionManagement";
import {UserTable} from "./components/users/UserTable";
import {ContextWrapper} from "./context/ContextWrapper";
import ResourceTable from "./components/resources/ResourceTable";
import InquiryTable from "./components/inquiries/inquiries/InquiryTable";
import ApprovalTable from "./components/inquiries/approvals/ApprovalTable";
import OrderTable from "./components/inquiries/orders/OrderTable";
import ShipmentPanel from "./components/inquiries/shipments/ShipmentPanel";
import {ROLE_ENUM} from "./api_enums/ROLE_ENUM";
import PackageUnitTable from "./components/packageunit/PackageUnitTable";
import Home from "./components/Home"
import StatsControlsBarchart from "./components/metrics/StatsControlsBarchart";

function ShowIndicator(props: { children: ReactNode }) {
    const {getOptions} = useContext(AuthContext) as AuthContextType;
    const {show_indicators} = getOptions();
    return show_indicators ? <>{props.children}</> : <></>;
}

function RequireAuth(props: { children: ReactNode }) {
    const {isLoggedIn} = useContext(AuthContext) as AuthContextType;
    return isLoggedIn() ? <>{props.children}</> : <Navigate to="/login" replace/>;
}

function RequireRole(props: { roles: ROLE_ENUM[], children: ReactNode }) {
    const {hasRight} = useContext(AuthContext) as AuthContextType;
    return hasRight(props.roles) ? <>{props.children}</> : <Navigate to="/" replace/>;
}

function PreferredColorScheme(props: { children: ReactNode }) {

    const {userInfo} = useContext(AuthContext) as AuthContextType;

    // hook will return either 'dark' or 'light' on client
    // and always 'light' during ssr as window.matchMedia is not available
    const preferredColorScheme = useColorScheme(userInfo?.user_options?.dark_mode ? 'dark' : 'light');
    const [colorScheme, setColorScheme] = useState<ColorScheme>(preferredColorScheme);
    const toggleColorScheme = (value?: ColorScheme) =>
        setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

    return <ColorSchemeProvider colorScheme={preferredColorScheme} toggleColorScheme={toggleColorScheme}>
        <MantineProvider theme={{
            colorScheme: colorScheme,
            primaryColor: 'gray'
        }}>
            {props.children}
        </MantineProvider>
    </ColorSchemeProvider>
}

function App() {

    return (
        <AuthProvider>
            <PreferredColorScheme>
                <BrowserRouter>
                    <Routes>
                        <Route path={"/"} element={
                            <RequireAuth>
                                <NotificationsProvider>
                                    <ContextWrapper>
                                        <AppShell
                                            padding="md"
                                            navbar={<AppNavbar/>}
                                            header={<AppHeader></AppHeader>}
                                            styles={(theme) => ({
                                                main: {
                                                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                                                    width: 'unset'
                                                },
                                            })}
                                        >
                                            {/* TODO: right place? as deep as possible but should not reload */}
                                            <Outlet/>
                                        </AppShell>
                                    </ContextWrapper>
                                </NotificationsProvider>
                            </RequireAuth>
                        }>
                            <Route path="/" element={
                                <RequireRole roles={[ROLE_ENUM.USER]}>
                                    <Home/>
                                </RequireRole>
                            }/>
                            <Route path="/inquiries" element={
                                <RequireRole roles={[ROLE_ENUM.REQUESTOR]}>
                                    {/* TODO: remove<StatsRing data={data.data}/> */}
                                    <ShowIndicator>
                                        <StatsControlsBarchart/>
                                    </ShowIndicator>
                                    <InquiryTable/>
                                </RequireRole>
                            }/>
                            <Route path="/inquiries_approve" element={
                                <RequireRole roles={[ROLE_ENUM.APPROVER]}>
                                    <ShowIndicator>
                                        <StatsControlsBarchart/>
                                    </ShowIndicator>
                                    <ApprovalTable/>
                                </RequireRole>
                            }/>
                            <Route path="/inquiries_purchase" element={
                                <RequireRole roles={[ROLE_ENUM.PURCHASER]}>
                                    <ShowIndicator>
                                        <StatsControlsBarchart/>
                                    </ShowIndicator>
                                    <OrderTable/>
                                </RequireRole>
                            }/>
                            <Route path="/orders_ship" element={
                                <RequireRole roles={[ROLE_ENUM.SHIPPER, ROLE_ENUM.SUPPLIER]}>
                                    <>
                                        <ShowIndicator>
                                            <StatsControlsBarchart/>
                                        </ShowIndicator>
                                        <ShipmentPanel/>
                                    </>
                                </RequireRole>
                            }/>
                            <Route path="/locations" element={
                                <RequireRole roles={[ROLE_ENUM.REQUESTOR, ROLE_ENUM.RECIPIENT]}>
                                    <Locationstable/>
                                </RequireRole>
                            }/>
                            <Route path="/settings" element={
                                <RequireRole roles={[ROLE_ENUM.USER]}>
                                    <UserSettings/>
                                </RequireRole>
                            }/>
                            <Route path="/resources" element={
                                <RequireRole roles={[ROLE_ENUM.SUPPLIER]}>
                                    <ResourceTable/>
                                </RequireRole>
                            }/>
                            <Route path="/vehicles" element={
                                <RequireRole roles={[ROLE_ENUM.LOGISTICAN]}>
                                    <VehicleTable/>
                                </RequireRole>
                            }/>
                            <Route path="/users" element={
                                <RequireRole roles={[ROLE_ENUM.MANAGER]}>
                                    <UserTable/>
                                </RequireRole>
                            }/>
                            <Route path="/sections" element={
                                <RequireRole roles={[ROLE_ENUM.USER, ROLE_ENUM.MANAGER]}>
                                    <SectionManagement/>
                                </RequireRole>
                            }/>
                            <Route path="/packageunit" element={
                                <RequireRole roles={[ROLE_ENUM.SUPPLIER, ROLE_ENUM.LOGISTICAN]}>
                                    <PackageUnitTable/>
                                </RequireRole>
                            }/>
                        </Route>
                        <Route path="/login" element={<Login/>}/>
                    </Routes>
                </BrowserRouter>
            </PreferredColorScheme>
        </AuthProvider>
    );
}

export default App;
