import React, { useState } from 'react';
import { ActionIcon, Modal } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';
import { PackageUnitForm } from './PackageUnitForm';
import IPackageUnit from '../../models/IPackageUnit';
import { useTranslation } from 'react-i18next';

export function PackageUnitEditDialog(props: { packageUnit: IPackageUnit }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('PackageUnitEditDialog.Title')}
        size="lg"
      >
        <PackageUnitForm
          create={false}
          packageUnit={props.packageUnit}
          onFinish={() => setOpened(false)}
        />
      </Modal>
      <ActionIcon
        color="orange"
        size="lg"
        variant="light"
        onClick={() => setOpened(true)}
        title={t('main.Edit')}
      >
        <Pencil />
      </ActionIcon>
    </>
  );
}
