import React, { useContext, useState } from 'react';
import { ActionIcon, Button, Group, Modal, Stack, Text } from '@mantine/core';
import { Trash } from 'tabler-icons-react';
import { showAppNotifcation } from '../../../utility/NotificationConfigs';
import { InquiryContext } from '../../../context/ContextWrapper';
import { AbstractContextType } from '../../../context/AbstractProvider';
import IInquiry, { getPrefixedInquiryId } from '../../../models/IInquiry';
import InquiryService from '../../../services/InquiryService';
import { useTranslation } from 'react-i18next';

export function InquiryDeleteDialog(props: { inquiry: IInquiry }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const ctx = useContext(InquiryContext) as AbstractContextType<IInquiry>;

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('InquiryDeleteDialog.Title')}
      >
        <>
          <Stack>
            <Text>
              {t('main.Question')} {getPrefixedInquiryId(props.inquiry.id)}{' '}
              {t('main.DeleteConfirm')}
            </Text>
            <Group>
              <Button
                color="red"
                onClick={() => {
                  InquiryService.remove(props.inquiry.id)
                    .then(() => {
                      ctx.remove(props.inquiry.id);
                      showAppNotifcation(
                        true,
                        t('InquiryDeleteDialog.ConfirmedDelete'),
                      );
                    })
                    .catch(() => {
                      showAppNotifcation(
                        false,
                        t('InquiryDeleteDialog.CannotDelete'),
                      );
                    })
                    .finally(() => setOpened(false));
                }}
              >
                {t('main.Delete')}
              </Button>
              <Button color="gray" onClick={() => setOpened(false)}>
                {t('main.Cancel')}
              </Button>
            </Group>
          </Stack>
        </>
      </Modal>

      <ActionIcon
        color="red"
        variant="light"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('main.Remove')}
      >
        <Trash />
      </ActionIcon>
    </>
  );
}
