import React, { useContext, useState } from 'react';
import {
  ActionIcon,
  Button,
  Grid,
  Group,
  Modal,
  NumberInput,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import { TruckDelivery } from 'tabler-icons-react';
import { showAppNotifcation } from '../../../../utility/NotificationConfigs';
import IOrderPosition from '../../../../models/IOrder';
import { useTranslation } from 'react-i18next';
import orderPositionService from '../../../../services/OrderPositionService';
import { DELIVERY_STATUS_ENUM } from '../../../../api_enums/DELIVERY_STATUS_ENUM';
import IDelivery, { getPrefixedDeliveryId } from '../../../../models/IDelivery';
import { AbstractContextType } from '../../../../context/AbstractProvider';
import { DeliveryContext } from '../../../../context/ContextWrapper';
import { ORDER_STATUS_ENUM } from '../../../../api_enums/ORDER_STATUS_ENUM';
import IVehicle from '../../../../models/IVehicle';
import IPackageUnit from '../../../../models/IPackageUnit';
import { VEHICLE_STATUS_ENUM_VALUES } from '../../../../api_enums/VEHICLE_STATUS_ENUM';
import { DeliveryForm } from '../Delivery/DeliveryForm';
import IInquiry, { getPrefixedInquiryId } from '../../../../models/IInquiry';
import { INQUIRY_PRIORITY_ENUM_VALUES } from '../../../../api_enums/INQUIRY_PRIORITY_ENUM';
import ILocation from '../../../../models/ILocation';
import IResource from '../../../../models/IResource';
import { useForm } from '@mantine/form';
import { DeliveryCreateDialog } from '../Delivery/DeliveryCreateDialog';

export function ShipmentDialog(props: { orderPosition: IOrderPosition }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const deliveryCtx = useContext(
    DeliveryContext,
  ) as AbstractContextType<IDelivery>;
  const [delivery, setDelivery] = useState<IDelivery | null>(null);
  const [shippedAmountValue, setShippedAmountValue] = useState(
    props.orderPosition?.remain_loading,
  );

  const checkPermission = () => {
    return (
      props.orderPosition.remain_loading != 0 &&
      (props.orderPosition.status === ORDER_STATUS_ENUM.PICKING ||
        props.orderPosition.status === ORDER_STATUS_ENUM.PARTIALLY_DISPATCHED ||
        props.orderPosition.status === ORDER_STATUS_ENUM.PARTIALLY_DELIVERED ||
        props.orderPosition.status === ORDER_STATUS_ENUM.PARTIALLY_LOADED)
    );
  };

  function createDeliveryPosition() {
    const orderPositionIds = [props.orderPosition.id];
    orderPositionService
      .load(orderPositionIds, delivery?.id, shippedAmountValue)
      .then(() => {
        showAppNotifcation(true, t('ShipmentDialog.OrderLoaded'));
      })
      .catch(() => {
        showAppNotifcation(false, t('ShipmentDialog.SelectDelivery'));
      })
      .finally(() => {
        setOpened(false);
        onClose();
      });
  }

  function onClose() {
    setOpened(false);
    setDelivery(null);
    setShippedAmountValue(props.orderPosition?.remain_loading);
  }

  const form = useForm({
    initialValues: {
      delivered: delivery?.id.toString() || '',
    },
    validate: {
      delivered: (value) =>
        value == null ? t('ShipmentDialog.SelectDeliverTo') : null,
    },
  });

  return (
    <>
      <Modal
        size="90rem"
        opened={opened}
        onClose={() => onClose()}
        title={
          t('ShipmentDialog.OrderItem') +
          ' ' +
          t('ShipmentDialog.AddToDelivery')
        }
      >
        <Stack>
          <Grid>
            <Grid.Col xs={10}>
              <Text size={'sm'}>{t('ShipmentDialog.OrderPositions')} :</Text>
            </Grid.Col>
            <Grid.Col xs={2}>
              <DeliveryCreateDialog />
            </Grid.Col>
            <Grid.Col xs={12}>
              <Text size={'sm'}>
                {getPrefixedInquiryId(
                  (props.orderPosition.inquired_through as IInquiry).id,
                ) +
                  ', ' +
                  INQUIRY_PRIORITY_ENUM_VALUES.get(props.orderPosition.priority)
                    .label +
                  ', ' +
                  props.orderPosition.remain_loading}
                /
                {props.orderPosition.ordered_amount +
                  ', ' +
                  (
                    (props.orderPosition.inquired_through as IInquiry)
                      ?.inquired_is as IResource
                  )?.name +
                  ', ' +
                  (
                    (
                      (props.orderPosition?.inquired_through as IInquiry)
                        ?.inquired_is as IResource
                    )?.package_unit as IPackageUnit
                  )?.name +
                  ', ' +
                  (
                    (props.orderPosition.inquired_through as IInquiry)
                      ?.deliver_to as ILocation
                  )?.name}
              </Text>
            </Grid.Col>
          </Grid>
          <form onSubmit={form.onSubmit(() => createDeliveryPosition())}>
            <Grid>
              <Grid.Col xs={12}>
                <NumberInput
                  style={{ width: '10rem' }}
                  size={'sm'}
                  label={t('main.Quantity')}
                  defaultValue={
                    props.orderPosition?.remaining_amount -
                    props.orderPosition?.loading_amount
                  }
                  min={1}
                  max={props.orderPosition?.remaining_amount}
                  value={shippedAmountValue}
                  required
                  onChange={(value) => setShippedAmountValue(value)}
                />
              </Grid.Col>
              <Grid.Col xs={12}>
                <Select
                  label={t('ShipmentDialog.Delivery')}
                  placeholder={t('ShipmentDialog.SelectDelivery')}
                  required
                  searchable
                  data={deliveryCtx.entities
                    .filter((d) => d.status === DELIVERY_STATUS_ENUM.PICKING)
                    .map((d) => {
                      return {
                        value: d.id.toString(),
                        label:
                          getPrefixedDeliveryId(d.id) +
                          ', ' +
                          (d.delivered_through as IVehicle)?.radio_call_name +
                          ', ' +
                          (!(d.delivered_through as IVehicle)?.license_plate
                            ? ''
                            : '(' +
                              (d.delivered_through as IVehicle)?.license_plate +
                              ')') +
                          ' ' +
                          (d.delivered_through as IVehicle)?.max_cargo +
                          ' ' +
                          (
                            (d.delivered_through as IVehicle)
                              ?.package_unit as IPackageUnit
                          )?.name +
                          ', ' +
                          (d.delivered_through as IVehicle)?.max_weight +
                          (d.delivered_through as IVehicle)?.weight_unit +
                          ', ' +
                          VEHICLE_STATUS_ENUM_VALUES.get(
                            (d.delivered_through as IVehicle)?.status,
                          )?.label,
                      };
                    })}
                  {...form.getInputProps('delivered')}
                  value={delivery?.id.toString()}
                  onChange={(e) => {
                    if (e) {
                      setDelivery(
                        deliveryCtx.entities
                          .filter((d) => d?.id.toString() === e)
                          .pop(),
                      );
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col>
                {delivery && (
                  <DeliveryForm
                    create={false}
                    loading={true}
                    onFinish={() => setOpened(false)}
                    delivery={delivery}
                    loadingAmount={shippedAmountValue}
                  />
                )}
              </Grid.Col>
            </Grid>
          </form>
          <Grid>
            <Grid.Col xs={10}>
              <Group>
                <Button
                  color="indigo"
                  disabled={!delivery}
                  onClick={() => {
                    createDeliveryPosition();
                  }}
                >
                  {t('ShipmentDialog.Load')}
                </Button>
                <Button color="gray" onClick={() => onClose()}>
                  {t('main.Cancel')}
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </Stack>
      </Modal>
      <ActionIcon
        disabled={!checkPermission()}
        color="green"
        variant="light"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('ShipmentDialog.Title')}
      >
        <TruckDelivery />
      </ActionIcon>
    </>
  );
}
