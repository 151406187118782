import React, { useState } from 'react';
import { ActionIcon, Modal } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';
import IUser from '../../models/IUser';
import { UserForm } from './UserForm';
import { useTranslation } from 'react-i18next';

export function UserEditDialog(props: { user: IUser }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        size="lg"
        title={t('UserEditDialog.Title')}
      >
        <UserForm
          sectionCreate={false}
          create={false}
          user={props.user}
          onFinish={() => setOpened(false)}
        />
      </Modal>

      <ActionIcon
        color="orange"
        size="lg"
        variant="light"
        onClick={() => setOpened(true)}
        title={t('main.Edit')}
      >
        <Pencil />
      </ActionIcon>
    </>
  );
}
