import IUser from './IUser';
import IInquiry from './IInquiry';
import IHistory from './IHistory';

export default interface IOrderPosition extends IHistory {
  id: number;
  pos_nr: number;
  status: string;
  priority: string;
  ordered_amount: number;
  remaining_amount: number;
  belongs_to: Partial<IOrder> | string;
  inquired_through: Partial<IInquiry> | string;
  created_at: Date | string;
  loaded_amount: number;
  loading_amount: number;
  remain_loading: number;
}

export interface IOrder {
  id: number;
  status: string;
  history: { status: string; at: Date }[];
  ordered_by: Partial<IUser>;
  created_at: Date | string;
}

export function getPrefixedOrderId(id: number) {
  return 'OR-' + id;
}

export function getPrefixedOrderPositionId(id: number) {
  return 'OP-' + id;
}
