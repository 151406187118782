import React, { useState } from 'react';
import { ActionIcon, Button, Group, Modal, Stack, Text } from '@mantine/core';
import { RefreshAlert } from 'tabler-icons-react';
import { showAppNotifcation } from '../../utility/NotificationConfigs';
import IUser from '../../models/IUser';
import UserService from '../../services/UserService';
import { useTranslation } from 'react-i18next';

export function UserRestoreDialog(props: { user: IUser }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('UserRestoreDialog.Title')}
      >
        <>
          <Stack>
            <Text>
              {t('UserRestoreDialog.PasswordResetConfirm', {
                name: props.user.name,
              })}{' '}
            </Text>
            <Group>
              <Button
                color="yellow"
                onClick={() => {
                  UserService.resetPassword(props.user.id)
                    .then((res: any) => {
                      showAppNotifcation(
                        true,
                        t('UserRestoreDialog.PasswordResetSuccessfully'),
                        'PASSWORT: ' + res.data.pw,
                        false,
                      );
                    })
                    .catch(() => {
                      showAppNotifcation(
                        false,
                        t('UserRestoreDialog.CanNotResetPassword'),
                      );
                    })
                    .finally(() => setOpened(false));
                }}
              >
                {t('UserRestoreDialog.Reset')}
              </Button>
              <Button color="gray" onClick={() => setOpened(false)}>
                {t('main.Cancel')}
              </Button>
            </Group>
          </Stack>
        </>
      </Modal>

      <ActionIcon
        color="yellow"
        variant="light"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('UserRestoreDialog.Title')}
      >
        <RefreshAlert />
      </ActionIcon>
    </>
  );
}
