import React, { useContext, useState } from 'react';
import { ActionIcon, Modal } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';
import { InquiryForm, InquiryFormMode } from './InquiryForm';
import IInquiry from '../../models/IInquiry';
import { useTranslation } from 'react-i18next';
import { AuthContext, AuthContextType } from '../../context/AuthProvider';
import ISection from '../../models/ISection';
import { INQUIRY_STATUS_ENUM } from '../../api_enums/INQUIRY_STATUS_ENUM';
import IUser from '../../models/IUser';

export function InquiryEditDialog(props: { inquiry: IInquiry }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const { userInfo } = useContext(AuthContext) as AuthContextType;

  /**
   *  Inquiry is editable if it is created by the current user or it is waiting for approve in the user's section.
   */
  const checkPermission = () => {
    return (
      ((props.inquiry?.is_waiting_at as ISection)?.id ===
        userInfo?.section_id ||
        (props.inquiry?.inquired_by as IUser)?.id === userInfo?.user_id ||
        !userInfo.section_supervisor_id) &&
      ((props.inquiry?.status as INQUIRY_STATUS_ENUM) ===
        INQUIRY_STATUS_ENUM.INQUIRED ||
        (props.inquiry?.status as INQUIRY_STATUS_ENUM) ===
          INQUIRY_STATUS_ENUM.IN_APPROVAL)
    );
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('InquiryEditDialog.Title')}
        size="lg"
      >
        <InquiryForm
          mode={InquiryFormMode.EDIT}
          inquiry={props.inquiry}
          onFinish={() => setOpened(false)}
        />
      </Modal>
      <ActionIcon
        disabled={!checkPermission()}
        color="orange"
        size="lg"
        variant="light"
        onClick={() => setOpened(true)}
        title={t('main.Edit')}
      >
        <Pencil />
      </ActionIcon>
    </>
  );
}
