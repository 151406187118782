import { createStyles, Skeleton, Table, Text } from '@mantine/core';

import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  getPrefixedDeliveryPositionId,
  IDeliveryPosition,
} from '../../../../models/IDeliveryPosition';
import { InquiryPriorityElement } from '../../InquiryPriorityElement';
import IOrderPosition from '../../../../models/IOrder';
import IInquiry, { getPrefixedInquiryId } from '../../../../models/IInquiry';
import ILocation from '../../../../models/ILocation';
import IResource from '../../../../models/IResource';
import IPackageUnit from '../../../../models/IPackageUnit';
import ISection from '../../../../models/ISection';

const useStyles = createStyles(() => ({
  stripe: {
    backgroundColor: '#f8f9fa',
  },
  table: {
    td: {
      wordBreak: 'break-word',
    },
  },
}));

function createRows(elements: IDeliveryPosition[]) {
  return elements.map((element: IDeliveryPosition, index) => {
    const { t } = useTranslation();
    const { classes } = useStyles();

    const comment = (
      (element.ordered_through as IOrderPosition)?.inquired_through as IInquiry
    )?.comment;

    return (
      <>
        <tr key={element.id} className={index % 2 === 0 ? classes.stripe : ''}>
          <td>
            {getPrefixedInquiryId(
              (
                (element.ordered_through as IOrderPosition as IOrderPosition)
                  .inquired_through as IInquiry
              ).id,
            )}
          </td>
          <td>{getPrefixedDeliveryPositionId(element.id)}</td>
          <td>
            <InquiryPriorityElement value={element.priority} />
          </td>
          <td>{(element.ordered_through as IOrderPosition)?.ordered_amount}</td>
          <td>{(element.ordered_through as IOrderPosition)?.remain_loading}</td>
          <td>{element?.shipped_amount}</td>
          <td>
            {
              (
                (
                  (element.ordered_through as IOrderPosition)
                    ?.inquired_through as IInquiry
                )?.inquired_is as IResource
              )?.name
            }
            (
            {
              (
                (
                  (
                    (element.ordered_through as IOrderPosition)
                      ?.inquired_through as IInquiry
                  )?.inquired_is as IResource
                )?.package_unit as IPackageUnit
              )?.name
            }
            )
          </td>
          <td>
            {
              (
                (
                  (element.ordered_through as IOrderPosition)
                    ?.inquired_through as IInquiry
                )?.deliver_to as ILocation
              )?.name
            }
          </td>
          <td>
            {
              (
                (
                  (element.ordered_through as IOrderPosition)
                    ?.inquired_through as IInquiry
                )?.ordered_from as ISection
              )?.name
            }
          </td>
          <td>
            {
              (
                (element.ordered_through as IOrderPosition)
                  ?.inquired_through as IInquiry
              )?.requester
            }
          </td>
          <td>
            {
              (
                (element.ordered_through as IOrderPosition)
                  ?.inquired_through as IInquiry
              )?.recipient
            }
          </td>
        </tr>
        {comment && (
          <tr className={index % 2 === 0 ? classes.stripe : ''}>
            <td style={{ border: 0 }}>
              <Text>{t('DeliveryPositionTable.comment')}:</Text>
            </td>
            <td colSpan={10} style={{ border: 0 }}>
              <Text style={{ fontWeight: 'bold' }}>{comment}</Text>
            </td>
          </tr>
        )}
      </>
    );
  });
}

function DeliveryPositionTable(props: {
  deliveryPositions: IDeliveryPosition[];
}) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <>
      <Table
        withBorder
        verticalSpacing="md"
        fontSize="md"
        className={classes.table}
      >
        <thead>
          <tr>
            <th>{t('main.InquiryNo')}</th>
            <th>{t('DeliveryForm.Position')}</th>
            <th>{t('main.Priority')}</th>
            <th>{t('main.Quantity')}</th>
            <th>{t('DeliveryForm.Remaining')}</th>
            <th>{t('DeliveryForm.Loaded')}</th>

            {/*<th>{t('main.Quantity')}</th>*/}
            {/*<th>{t('DeliveryForm.Remaining')}</th>*/}
            <th>{t('main.Resource')}</th>
            <th>{t('main.DeliveryLocation')}</th>
            <th>{t('main.Supplier')}</th>
            <th>{t('main.requester')}</th>
            <th>{t('main.recipient')}</th>
          </tr>
        </thead>
        <tbody>
          {props.deliveryPositions && createRows(props.deliveryPositions)}
          {!props.deliveryPositions &&
            /* TODO ajust skeleton */
            [1, 2].map((id) => {
              return (
                <tr key={id}>
                  <td>
                    <Skeleton height={12} mt={6} width="20%" radius="xl" />
                  </td>
                  <td>
                    <Skeleton height={12} mt={6} width="30%" radius="xl" />
                  </td>
                  <td>
                    <Skeleton height={12} mt={6} width="20%" radius="xl" />
                  </td>
                  <td>
                    <Skeleton height={12} mt={6} width="10%" radius="xl" />
                  </td>
                  <td>
                    <Skeleton height={12} mt={6} width="10%" radius="xl" />
                  </td>
                  <td>
                    <Skeleton height={12} mt={6} width="10%" radius="xl" />
                  </td>
                  <td>
                    <Skeleton height={12} mt={6} width="10%" radius="xl" />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {props.deliveryPositions && props.deliveryPositions.length === 0 && (
        <Text align="center">
          {t('DeliveryPositionTable.NoDeliveryAvailable')}
        </Text>
      )}
    </>
  );
}

export default DeliveryPositionTable;
