import {
  FilePlus,
  Icon,
  ReportMoney,
  TrafficLights,
  TruckDelivery,
} from 'tabler-icons-react';

enum INQUIRY_STATUS_ENUM {
  INQUIRED = 'inquired',
  IN_APPROVAL = 'in_approval',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  ORDERED = 'ordered',
  PICKING = 'picking',
  LOADED = 'loaded',
  PARTIALLY_DISPATCHED = 'partially_dispatched',
  DISPATCHED = 'dispatched',
  CANCELLED = 'cancelled',
  ON_DELIVERY = 'on_delivery',
  DELIVERED = 'delivered',
  PARTIALLY_DELIVERED = 'partially_delivered',
  PARTIALLY_LOADED = 'partially_loaded',
}

const INQUIRY_STATUS_ENUM_VALUES = new Map<
  string,
  { label: string; icon: Icon; color: string, priority: number }
>([
  [
    INQUIRY_STATUS_ENUM.INQUIRED,
    { label: 'Angefragt', icon: FilePlus, color: 'orange', priority: 0 },
  ],
  [
    INQUIRY_STATUS_ENUM.IN_APPROVAL,
    { label: 'Im Freigabeprozess', icon: TrafficLights, color: 'dark', priority: 1 },
  ],
  [
    INQUIRY_STATUS_ENUM.APPROVED,
    { label: 'Freigegeben', icon: ReportMoney, color: 'dark', priority: 2 },
  ],
  [
    INQUIRY_STATUS_ENUM.REJECTED,
    { label: 'Abgelehnt', icon: TruckDelivery, color: 'red', priority: 3 },
  ],
  [
    INQUIRY_STATUS_ENUM.ORDERED,
    { label: 'Bestellt', icon: ReportMoney, color: 'green', priority: 4 },
  ],
  [
    INQUIRY_STATUS_ENUM.PICKING,
    { label: 'Wird vorbereitet', icon: FilePlus, color: 'dark', priority: 5 },
  ],
  [
    INQUIRY_STATUS_ENUM.LOADED,
    { label: 'Verladen', icon: FilePlus, color: 'grey', priority: 7 },
  ],
  [
    INQUIRY_STATUS_ENUM.PARTIALLY_DISPATCHED,
    { label: 'Teilweise versendet', icon: TrafficLights, color: 'dark', priority: 8 },
  ],
  [
    INQUIRY_STATUS_ENUM.DISPATCHED,
    { label: 'Versendet', icon: ReportMoney, color: 'grey', priority: 9 },
  ],
  [
    INQUIRY_STATUS_ENUM.CANCELLED,
    { label: 'Storniert', icon: TruckDelivery, color: 'grey', priority: 13 },
  ],
  [
    INQUIRY_STATUS_ENUM.ON_DELIVERY,
    { label: 'Unterwegs', icon: FilePlus, color: 'orange', priority: 10 },
  ],
  [
    INQUIRY_STATUS_ENUM.PARTIALLY_DELIVERED,
    { label: 'Teilweise Ausgeliefert', icon: TrafficLights, color: 'grey', priority: 11 },
  ],
  [
    INQUIRY_STATUS_ENUM.DELIVERED,
    { label: 'Ausgeliefert', icon: TrafficLights, color: 'green', priority: 12 },
  ],
  [
    INQUIRY_STATUS_ENUM.PARTIALLY_LOADED,
    { label: 'Teilweise Verladen', icon: FilePlus, color: 'grey', priority: 6 },
  ],
]);

export { INQUIRY_STATUS_ENUM, INQUIRY_STATUS_ENUM_VALUES };
