import React, { useState } from 'react';
import {ActionIcon, Center, Modal, Text} from '@mantine/core';
import {InfoCircle, Printer} from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';
import IDelivery from '../../../../models/IDelivery';
import { DeliveryPrintForm } from './DeliveryPrintForm';

export function DeliveryPrintDialog(props: { delivery: IDelivery }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={
        <Center>
          <span>{t("DeliveryPrintDialog.Title")}</span>
          <Center pl={8}>
            <InfoCircle color="red" />
            <Text style={{fontWeight: "bold"}} color="red">{t("DeliveryPrintDialog.Info")}</Text>
          </Center>
        </Center>
      }
        size="90%"
      >
        <DeliveryPrintForm
          delivery={props.delivery}
          onFinish={() => setOpened(false)}
        ></DeliveryPrintForm>
      </Modal>

      <ActionIcon
        color="green"
        size="lg"
        variant="light"
        onClick={() => setOpened(true)}
        title={t('main.Print')}
      >
        <Printer />
      </ActionIcon>
    </>
  );
}
