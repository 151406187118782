import React, { useContext, useState } from 'react';
import {
  ActionIcon,
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Modal,
  NumberInput,
  Skeleton,
  Table,
  Text,
  TextInput,
} from '@mantine/core';
import { Check, CircleX, ClipboardCheck, X } from 'tabler-icons-react';
import IDelivery, { getPrefixedDeliveryId } from '../../models/IDelivery';
import { useTranslation } from 'react-i18next';
import { AbstractContextType } from '../../context/AbstractProvider';
import { DeliveryContext } from '../../context/ContextWrapper';
import IInquiry, { getPrefixedInquiryId } from '../../models/IInquiry';
import IOrderPosition from '../../models/IOrder';
import IVehicle from '../../models/IVehicle';
import ILocation from '../../models/ILocation';
import { DeliveryStatusElement } from '../inquiries/DeliveryStatusElement';
import { getPrefixedDeliveryPositionId } from '../../models/IDeliveryPosition';
import { showAppNotifcation } from '../../utility/NotificationConfigs';
import DeliveryPositionService from '../../services/DeliveryPositionService';
import { DELIVERY_STATUS_ENUM } from '../../api_enums/DELIVERY_STATUS_ENUM';
import { AuthContext, AuthContextType } from '../../context/AuthProvider';
import { ROLE_ENUM } from '../../api_enums/ROLE_ENUM';

function DeliveriesList(props: { searchValue: string }) {
  const ctxDelivery = useContext(
    DeliveryContext,
  ) as AbstractContextType<IDelivery>;
  const { userInfo } = useContext(AuthContext) as AuthContextType;
  const { t } = useTranslation();

  return (
    <>
      <Table striped verticalSpacing="xs" fontSize="md">
        <thead>
          <tr>
            <th>{t('main.DeliveryNo')}</th>
            <th>{t('main.DeliveryPositionNo')}</th>
            <th>{t('main.InquiryNo')}</th>
            <th>{t('main.Status')}</th>
            <th>{t('AcceptDeliveryDialog.Vehicle')}</th>
            <th>{t('main.DeliveryLocation')}</th>
            <th>{t('main.Quantity')}</th>
            <th>{t('DeliveryForm.shippedAmount')}</th>
            <th>{t('DeliveryForm.Remaining')}</th>
            <th>{t('main.Received')}</th>
            <th>{t('main.Reject')}</th>
          </tr>
        </thead>
        <tbody>
          {ctxDelivery.entities &&
            ctxDelivery.entities.map((delivery) => {
              return (
                delivery.positions &&
                delivery.positions
                  .filter((position) => {
                    return (
                      props.searchValue === '' ||
                      getPrefixedDeliveryId(delivery.id)
                        .toLowerCase()
                        .includes(props.searchValue.toLowerCase().trim()) ||
                      getPrefixedDeliveryId(delivery.id)
                        .toLowerCase()
                        .includes(props.searchValue.toLowerCase().trim()) ||
                      getPrefixedDeliveryPositionId(position.id)
                        .toLowerCase()
                        .includes(props.searchValue.toLowerCase().trim()) ||
                      getPrefixedInquiryId(
                        (
                          (position.ordered_through as IOrderPosition)
                            ?.inquired_through as IInquiry
                        )?.id,
                      )
                        .toLowerCase()
                        .includes(props.searchValue.toLowerCase().trim())
                    );
                  })
                  .map((position) => {
                    const checkPermission = () => {
                      return (
                        (position?.status as DELIVERY_STATUS_ENUM) ===
                          DELIVERY_STATUS_ENUM.ON_DELIVERY &&
                        (userInfo?.user_roles.includes(
                          ROLE_ENUM.ADMINISTRATOR,
                        ) ||
                          userInfo?.section_roles.includes(
                            ROLE_ENUM.ADMINISTRATOR,
                          ) ||
                          (
                            (
                              (position.ordered_through as IOrderPosition)
                                ?.inquired_through as IInquiry
                            )?.deliver_to as ILocation
                          ).belongs_to === userInfo?.section_id) &&
                        (userInfo?.user_roles.includes(
                          ROLE_ENUM.ADMINISTRATOR,
                        ) ||
                          userInfo?.section_roles.includes(
                            ROLE_ENUM.ADMINISTRATOR,
                          ) ||
                          userInfo?.user_roles.includes(ROLE_ENUM.RECIPIENT) ||
                          userInfo?.section_roles.includes(
                            ROLE_ENUM.RECIPIENT,
                          ) ||
                          userInfo?.user_roles.includes(
                            ROLE_ENUM.ADMINISTRATOR,
                          ) ||
                          userInfo?.section_roles.includes(
                            ROLE_ENUM.ADMINISTRATOR,
                          ))
                      );
                    };

                    let received_amount =
                      position.received_amount === 0 && checkPermission()
                        ? 1
                        : position.received_amount;
                    return (
                      <tr key={position?.id}>
                        <td>{getPrefixedDeliveryId(delivery?.id)}</td>
                        <td>{getPrefixedDeliveryPositionId(position?.id)}</td>
                        <td>
                          {getPrefixedInquiryId(
                            (
                              (position.ordered_through as IOrderPosition)
                                ?.inquired_through as IInquiry
                            )?.id,
                          )}
                        </td>
                        <td>
                          <DeliveryStatusElement status={position?.status} />
                        </td>
                        <td>
                          {
                            (delivery.delivered_through as IVehicle)
                              ?.radio_call_name
                          }
                          {!(delivery.delivered_through as IVehicle)
                            ?.license_plate
                            ? ''
                            : '(' +
                              (delivery.delivered_through as IVehicle)
                                ?.license_plate +
                              ')'}
                        </td>
                        <td>
                          {
                            (
                              (
                                (position.ordered_through as IOrderPosition)
                                  ?.inquired_through as IInquiry
                              )?.deliver_to as ILocation
                            )?.name
                          }
                        </td>
                        <td>
                          {
                            (position.ordered_through as IOrderPosition)
                              ?.ordered_amount
                          }
                        </td>
                        <td>{position.shipped_amount}</td>
                        <td>
                          {
                            (position.ordered_through as IOrderPosition)
                              ?.remaining_amount
                          }
                        </td>
                        <td>
                          <Group>
                            <NumberInput
                              disabled={!checkPermission()}
                              style={{ width: 80 }}
                              defaultValue={position?.shipped_amount}
                              value={position.shipped_amount}
                              min={1}
                              max={position.shipped_amount}
                              onChange={(v) => {
                                received_amount = v;
                              }}
                              required
                            />
                            <ActionIcon
                              disabled={!checkPermission()}
                              onClick={() => {
                                DeliveryPositionService.receive(
                                  position.id,
                                  received_amount,
                                )
                                  .then(() => {
                                    showAppNotifcation(
                                      true,
                                      t(
                                        'AcceptDeliveryDialog.ConfirmedReceive',
                                      ),
                                    );
                                  })
                                  .catch(() => {
                                    showAppNotifcation(
                                      false,
                                      t('AcceptDeliveryDialog.CannotReceive'),
                                    );
                                  })
                                  .finally();
                              }}
                              color="green"
                              variant="filled"
                            >
                              <Check />
                            </ActionIcon>
                          </Group>
                        </td>
                        <td>
                          <ActionIcon
                            disabled={!checkPermission()}
                            onClick={() => {
                              DeliveryPositionService.reject(position.id)
                                .then(() => {
                                  showAppNotifcation(
                                    true,
                                    t('AcceptDeliveryDialog.RejectReceive'),
                                  );
                                })
                                .catch(() => {
                                  showAppNotifcation(
                                    false,
                                    t('AcceptDeliveryDialog.CannotReject'),
                                  );
                                })
                                .finally();
                            }}
                            color="red"
                            variant="filled"
                          >
                            <CircleX />
                          </ActionIcon>
                        </td>
                      </tr>
                    );
                  })
              );
            })}
          {!ctxDelivery.entities &&
            [1, 2, 3].map((id) => {
              return (
                <tr key={id}>
                  <td>
                    <Skeleton height={12} mt={6} width="30%" radius="xl" />
                  </td>
                  <td>
                    <Skeleton height={12} mt={6} width="60%" radius="xl" />
                  </td>
                  <td>
                    <Skeleton height={12} mt={6} width="40%" radius="xl" />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {!ctxDelivery.entities && ctxDelivery.entities.length === 0 && (
        <Text align="center">{t('AcceptDeliveryDialog.NoDeliveries')}</Text>
      )}
    </>
  );
}

// TODO check what happens if not all deliveries are in cache/loaded and somebody searches for such a delivery
// TODO in such a case hit the api and maybe append it
export function AcceptDeliveryDialog(props: {
  button: boolean;
  disabled: boolean;
}) {
  const [opened, setOpened] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const openDialog = () => setOpened(true);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
          setSearchValue('');
        }}
        title={t('AcceptDeliveryDialog.DeliveryTitle')}
        size="auto"
      >
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={visible} />
          <TextInput
            placeholder={'DE-1 oder DP-1 oder BA-1'}
            label={t('AcceptDeliveryDialog.DeliverySearchLabel')}
            radius="md"
            size="md"
            rightSection={
              searchValue && (
                <ActionIcon onClick={() => setSearchValue('')}>
                  {' '}
                  <X />{' '}
                </ActionIcon>
              )
            }
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Divider my="sm" />

          <DeliveriesList searchValue={searchValue} />
        </div>
      </Modal>
      {props.button && (
        <Button
          disabled={props.disabled}
          leftIcon={<ClipboardCheck />}
          variant="light"
          color="teal"
          onClick={openDialog}
        >
          {t('AcceptDeliveryDialog.DeliveryTitle')}
        </Button>
      )}
      {!props.button && (
        <ActionIcon
          color="teal"
          variant="light"
          size="lg"
          title={t('main.DeliveryTitle')}
          onClick={openDialog}
        >
          <ClipboardCheck />
        </ActionIcon>
      )}
    </>
  );
}
