import {
  Checkbox,
  Group,
  Paper,
  Skeleton,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';

import { PackageUnitEditDialog } from './PackageUnitEditDialog';
import { PackageUnitDeleteDialog } from './PackageUnitDeleteDialog';
import { PackageUnitCreateDialog } from './PackageUnitCreateDialog';
import React, { useContext } from 'react';
import IPackageUnit from '../../models/IPackageUnit';
import { PackageUnitContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import { useTranslation } from 'react-i18next';

function createRows(elements: IPackageUnit[]) {
  return elements.map((element) => {
    return (
      <tr key={element.id}>
        <td> {element.name} </td>
        <td> {element.description} </td>
        <td>
          <Checkbox checked={element.is_active} disabled />
        </td>
        <td>
          <Group spacing="xs">
            <PackageUnitEditDialog packageUnit={element} />
            <PackageUnitDeleteDialog packageUnit={element} />
          </Group>
        </td>
      </tr>
    );
  });
}

function PackageUnitTable() {
  const { t } = useTranslation();
  const ctx = useContext(
    PackageUnitContext,
  ) as AbstractContextType<IPackageUnit>;

  // TODO show skeleton when data is being loaded
  return (
    <Paper withBorder radius="md" p="xs">
      <Stack>
        <Title order={2}>{t('PackageUnitTable.Title')}</Title>
        <Group>
          <PackageUnitCreateDialog />
        </Group>
        <Table striped verticalSpacing="xs" fontSize="md">
          <thead>
            <tr>
              <th>{t('PackageUnitTable.Name')}</th>
              <th>{t('PackageUnitTable.Description')}</th>
              <th>{t('main.Active')}</th>
              <th>{t('main.Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {createRows(ctx.entities)}

            {ctx.loading &&
              /* TODO ajust skeleton */
              [1, 2].map((id) => {
                return (
                  <tr key={id}>
                    <td>
                      <Skeleton height={12} mt={6} width="50%" radius="xl" />
                    </td>
                    <td>
                      <Skeleton height={12} mt={6} width="70%" radius="xl" />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {!ctx.loading && ctx.entities.length == 0 && (
          <Text align="center">
            {t('PackageUnitTable.NoPackagingUnitAvailable')}
          </Text>
        )}
      </Stack>
    </Paper>
  );
}

export default PackageUnitTable;
