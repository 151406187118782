
enum VEHICLE_STATUS_ENUM {
    WILLBESUPPLIED = 'will_be_supplied',
    IDLE = 'idle',
    WAITING = 'waiting',
    LOADING = 'loading',
    SHIPPING ='shipping',
    WILLBEDEDUCTED = 'will_be_deducted',
    NEEDEDELSEWHERE = 'needed_elsewhere',
    DRIVERLESS = 'driverless',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    INREPAIR = 'in_repair',
    BROKEN = 'broken'
}

const VEHICLE_STATUS_ENUM_VALUES = new Map<string, {label: string}>([
    [VEHICLE_STATUS_ENUM.WILLBESUPPLIED, {label: 'Wird bereitgestellt'}],
    [VEHICLE_STATUS_ENUM.IDLE, {label: 'Untätig'}],
    [VEHICLE_STATUS_ENUM.WAITING, {label: 'Wartend'}],
    [VEHICLE_STATUS_ENUM.LOADING, {label: 'Wird beladen'}],
    [VEHICLE_STATUS_ENUM.SHIPPING, {label: 'Liefert aus'}],
    [VEHICLE_STATUS_ENUM.WILLBEDEDUCTED, {label: 'Wird abgezogen'}],
    [VEHICLE_STATUS_ENUM.NEEDEDELSEWHERE, {label: 'Feuerwehrauto'}],
    [VEHICLE_STATUS_ENUM.DRIVERLESS, {label: 'Fahrerlos'}],
    [VEHICLE_STATUS_ENUM.ACTIVE, {label: 'Aktiv'}],
    [VEHICLE_STATUS_ENUM.INACTIVE, {label: 'Inaktiv'}],
    [VEHICLE_STATUS_ENUM.INREPAIR, {label: 'In Reparatur'}],
    [VEHICLE_STATUS_ENUM.BROKEN, {label: 'Defekt'}]
]);

export {
    VEHICLE_STATUS_ENUM,
    VEHICLE_STATUS_ENUM_VALUES
};