import {
  Group,
  Paper,
  ScrollArea,
  SimpleGrid,
  Skeleton,
  Space,
  Stack,
  Switch,
  Table,
  Text,
  Title,
} from '@mantine/core';

import React, { useContext } from 'react';
import IInquiry, { getPrefixedInquiryId } from '../../../../models/IInquiry';
import { OrderPositionContext } from '../../../../context/ContextWrapper';
import { AbstractContextType } from '../../../../context/AbstractProvider';
import IOrderPosition from '../../../../models/IOrder';
import { OrderStatusElement } from '../../OrderStatusElement';
import { InquiryPriorityElement } from '../../InquiryPriorityElement';
import { ShipmentDialog } from './ShipmentDialog';
import ILocation from '../../../../models/ILocation';
import ISection from '../../../../models/ISection';
import { AuthContext, AuthContextType } from '../../../../context/AuthProvider';
import { useTranslation } from 'react-i18next';
import { useInputState } from '@mantine/hooks';
import UserService from '../../../../services/UserService';
import { showAppNotifcation } from '../../../../utility/NotificationConfigs';
import IResource from '../../../../models/IResource';
import { ORDER_STATUS_ENUM } from '../../../../api_enums/ORDER_STATUS_ENUM';
import { sortOrderPostionsByStatusPriorityId } from '../../../../utility/sorter';
import { OrderPositionDeleteDialog } from './OrderPositionDeleteDialog';
import { ROLE_ENUM } from '../../../../api_enums/ROLE_ENUM';

function createRows(
  elements: IOrderPosition[],
  sectionId: number,
  userSectionRoles: string[],
  showAllEntries: boolean,
) {
  return elements
    .filter((element) => {
      return (
        (showAllEntries &&
          ((
            ((element as IOrderPosition)?.inquired_through as IInquiry)
              ?.ordered_from as ISection
          )?.id === sectionId ||
            userSectionRoles.includes(ROLE_ENUM.MANAGER) ||
            userSectionRoles.includes(ROLE_ENUM.ADMINISTRATOR))) ||
        (((
          ((element as IOrderPosition)?.inquired_through as IInquiry)
            ?.ordered_from as ISection
        )?.id === sectionId ||
          userSectionRoles.includes(ROLE_ENUM.MANAGER) ||
          userSectionRoles.includes(ROLE_ENUM.ADMINISTRATOR)) &&
          (element as IOrderPosition).status != ORDER_STATUS_ENUM.LOADED &&
          (element as IOrderPosition).status != ORDER_STATUS_ENUM.DELIVERED &&
          (element as IOrderPosition).status != ORDER_STATUS_ENUM.ON_DELIVERY)
      );
    })
    .sort((a, b) => sortOrderPostionsByStatusPriorityId(a, b))
    .map((element: IOrderPosition) => {
      return (
        <>
          <tr key={element.id}>
            <td>
              {getPrefixedInquiryId(
                (element?.inquired_through as IInquiry)?.id,
              )}
            </td>
            <td>
              <OrderStatusElement status={element.status} />
            </td>
            <td>
              <InquiryPriorityElement value={element.priority} />
            </td>
            <td>{element?.remain_loading ? element?.remain_loading : 0}</td>
            <td> {element?.ordered_amount}</td>
            <td>
              {
                (
                  (element.inquired_through as IInquiry)
                    ?.inquired_is as IResource
                )?.name
              }
            </td>
            <td>
              {
                (
                  (element.inquired_through as IInquiry)
                    ?.deliver_to as ILocation
                )?.name
              }
            </td>
            <td>
              <Group spacing="xs">
                <ShipmentDialog orderPosition={element} />
                <OrderPositionDeleteDialog orderPosition={element} />
              </Group>
            </td>
          </tr>
        </>
      );
    });
}

function ShipmentTable() {
  const { t } = useTranslation();
  const ctxOrderPosition = useContext(
    OrderPositionContext,
  ) as AbstractContextType<IOrderPosition>;
  const { userInfo } = useContext(AuthContext) as AuthContextType;
  const { getOptions, setOptions } = useContext(AuthContext) as AuthContextType;
  const options = getOptions();
  const [showAllEntries, setShowAllEntries] = useInputState(
    options.show_order_position_indicators,
  );

  const updateOptions = (show_order_position_indicators: boolean) => {
    const opt = {
      ...options,
      show_order_position_indicators: show_order_position_indicators,
    };
    UserService.saveOptions({ options: opt })
      .then(() => {
        setOptions(opt);
      })
      .catch(() => showAppNotifcation(false, t('main.OptionCannotChange')));
  };

  // TODO show skeleton when data is being loaded
  return (
    <Paper withBorder radius="md" p="xs">
      <Stack>
        <Title order={2}>{t('ShipmentTable.OrderPositions')}</Title>
        <SimpleGrid cols={2}>
          <Group></Group>
          <Group position="right">
            <Switch
              labelPosition="left"
              label={t('ShipmentTable.ShowAllOrders')}
              color="green"
              checked={showAllEntries}
              onChange={(event) => {
                setShowAllEntries(event.currentTarget.checked);
                updateOptions(event.currentTarget.checked);
              }}
            />
            <Space w="xs" />
          </Group>
        </SimpleGrid>
        <ScrollArea style={{ width: 'auto', height: 'auto' }} type="always">
          <Table striped verticalSpacing="xs" fontSize="md">
            <thead>
              <tr>
                <th>{t('main.RequirementNo')}</th>
                <th>{t('main.Status')}</th>
                <th>{t('main.Priority')}</th>
                <th>{t('OrderPositionTable.Remaining')}</th>
                <th>{t('OrderPositionTable.Quantity')}</th>
                <th>{t('main.Resource')}</th>
                <th>{t('main.DeliveryLocation')}</th>
                <th>{t('main.Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {createRows(
                ctxOrderPosition.entities,
                userInfo?.section_id || 0,
                userInfo?.section_roles,
                showAllEntries,
              )}
              {ctxOrderPosition.loading &&
                /* TODO adjust skeleton */
                [1, 2].map((id) => {
                  return (
                    <tr key={id}>
                      <td>
                        <Skeleton height={12} mt={6} width="50%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="70%" radius="xl" />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </ScrollArea>
        {!ctxOrderPosition.loading && ctxOrderPosition.entities.length == 0 && (
          <Text align="center">
            {t('OrderPositionTable.NoOrdersAvailable')}
          </Text>
        )}
      </Stack>
    </Paper>
  );
}

export default ShipmentTable;
