import React, { useContext, useState } from 'react';
import { ActionIcon, Button, Group, Modal, Select, Stack } from '@mantine/core';
import { ShoppingCart } from 'tabler-icons-react';
import { showAppNotifcation } from '../../../utility/NotificationConfigs';
import IInquiry from '../../../models/IInquiry';
import InquiryService from '../../../services/InquiryService';
import { getPrefixedOrderId } from '../../../models/IOrder';
import { SectionContext } from '../../../context/ContextWrapper';
import { AbstractContextType } from '../../../context/AbstractProvider';
import ISection from '../../../models/ISection';
import { ROLE_ENUM } from '../../../api_enums/ROLE_ENUM';
import { useTranslation } from 'react-i18next';
import { useForm } from '@mantine/form';
import { INQUIRY_STATUS_ENUM } from '../../../api_enums/INQUIRY_STATUS_ENUM';
import IUser from '../../../models/IUser';
import { AuthContext, AuthContextType } from '../../../context/AuthProvider';

export function OrderDialog(props: { inquiry: IInquiry }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const sectionCtx = useContext(
    SectionContext,
  ) as AbstractContextType<ISection>;
  const [supplier, setSupplier] = useState<string | null>(null);
  const { userInfo } = useContext(AuthContext) as AuthContextType;

  const checkPermission = () => {
    return (
      ((props.inquiry?.is_waiting_at as ISection)?.id ===
        userInfo?.section_id ||
        (props.inquiry?.inquired_by as IUser)?.id === userInfo?.user_id) &&
      (props.inquiry?.status as INQUIRY_STATUS_ENUM) ===
        INQUIRY_STATUS_ENUM.APPROVED
    );
  };

  const orderInquiry = (values: { ordered_from: string }) => {
    const inquiryIds = [props.inquiry.id];
    InquiryService.order(inquiryIds, parseInt(values?.ordered_from))
      .then(() => {
        showAppNotifcation(true, t('OrderDialog.RequisitionOrdered'));
      })
      .catch(() => {
        showAppNotifcation(false, t('OrderDialog.CouldNotOrder'));
      })
      .finally(() => setOpened(false));
  };

  const form = useForm({
    initialValues: {
      ordered_from:
        (props.inquiry?.ordered_from as ISection)?.id.toString() || '',
    },
    validate: {
      ordered_from: (value) =>
        !value ? 'Bitte Einsatzabschnitt auswählen' : null,
    },
  });

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={
          t('OrderDialog.DemandRequest') +
          getPrefixedOrderId(props.inquiry.id) +
          t('OrderDialog.FromSupplier')
        }
      >
        <>
          <form onSubmit={form.onSubmit(orderInquiry)}>
            <Stack>
              {/* TODO a supplier can also be a logistican?? */}
              <Select
                label={t('main.Supplier')}
                placeholder={t('OrderDialog.SelectSupplier')}
                required
                data={sectionCtx.entities
                  .filter((s) => s.roles.includes(ROLE_ENUM.SUPPLIER))
                  .map((s) => {
                    return { value: s.id.toString(), label: s.name };
                  })}
                value={supplier}
                {...form.getInputProps('ordered_from')}
              />

              <Group>
                <Button type="submit" color="indigo" disabled={!form.isValid()}>
                  {t('OrderDialog.Order')}
                </Button>
                <Button color="gray" onClick={() => setOpened(false)}>
                  {t('main.Cancel')}
                </Button>
              </Group>
            </Stack>
          </form>
        </>
      </Modal>

      <ActionIcon
        disabled={!checkPermission()}
        color="green"
        variant="light"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('OrderDialog.Order')}
      >
        <ShoppingCart />
      </ActionIcon>
    </>
  );
}
